import * as Sentry from '@sentry/sveltekit';

const RELEASE = `abc-mkt-web-classic@${__APP_VERSION__}`; // eslint-disable-line

const getEnvironment = () => {
	if (window.location.hostname === 'www.abcmouse.com') return 'production';
	if (window.location.hostname === 'stage.abcmouse.com') return 'staging';
	return 'development';
};
const environment = getEnvironment();

const IGNORE_ERRORS = ['User Cancelled', 'UserCancelled', /user cancelled/i];

Sentry.init({
	dsn: 'https://301f36138b8fb5eadb575346186d3219@o968153.ingest.us.sentry.io/4507946504421376',
	// tracesSampleRate: environment === 'production' ? 0.3 : 1,
	tracesSampleRate: 1, //TODO: set back to 0.3 once traffic is back to normal
	environment,
	release: RELEASE,
	// replaysSessionSampleRate: 0.1,
	// replaysOnErrorSampleRate: 1,
	normalizeDepth: 6,
	// integrations: [
	//   Sentry.replayIntegration()
	// ],
	ignoreErrors: IGNORE_ERRORS,
	beforeSend(event) {
		// Ignore errors in localhost, log to console
		if (window.location.hostname === 'localhost') {
			console.log('Sentry Error Event:', event);
			return null;
		}

		// Additional check for ignored errors
		if (event.exception && event.exception.values) {
			const errorMessage = event.exception.values[0]?.value;
			if (
				IGNORE_ERRORS.some((ignored) =>
					typeof ignored === 'string' ? errorMessage?.includes(ignored) : ignored.test(errorMessage)
				)
			) {
				return null;
			}
		}

		return event;
	}
});

export const handleError = Sentry.handleErrorWithSentry();
