import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20')
];

export const server_loads = [];

export const dictionary = {
		"/": [2],
		"/_home": [3],
		"/aa-offer": [4],
		"/activate-device": [5],
		"/assessment-purchase": [6],
		"/download-app": [7],
		"/expired": [8],
		"/inside-pw": [9],
		"/lg-tv": [10],
		"/login": [11],
		"/logout": [12],
		"/prospect-register": [13],
		"/purchase-gift": [14],
		"/reactivation": [15],
		"/redeem": [16],
		"/subscription": [17],
		"/subscription/offer/[offer_hash]": [18],
		"/terminate": [19],
		"/upgrade": [20]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';